/**
 * Update date: 12-05-2023
 * Screen 1042.5
 */

import LoadPanel from 'app/components/LoadPanel';
import TabPanel, { Item } from 'devextreme-react/tab-panel';
import useDictionary from 'hooks/useDictionary';
import React, { useContext, createContext } from 'react';
const TabOption = React.lazy(() => import('./OptionTab'));
const ReceivableManagementTab = React.lazy(
  () => import('./ReceivableManagementTab'),
);
const SettingAlarmTab = React.lazy(() => import('./SettingAlarmTab'));

// create context
const ModuleContext = createContext({});
ModuleContext.displayName = 'CenterOptionRegisContext';
export const useModuleContext = () => {
  return useContext(ModuleContext);
};

function CenterOptionRegis() {
  const { t }: any = useDictionary({ programId: '1042.5' });

  const value: any = {};
  /**
   * render
   */
  return (
    <ModuleContext.Provider value={value}>
      <React.Suspense fallback={<LoadPanel visible={true} />}>
        <TabPanel defaultSelectedIndex={0}>
          <Item title={t('Option')}>
            <TabOption />
          </Item>
          <Item title={t('Receivable Management')}>
            <ReceivableManagementTab />
          </Item>
          <Item title={t('Setting Alarm')}>
            <SettingAlarmTab />
          </Item>
        </TabPanel>
      </React.Suspense>
    </ModuleContext.Provider>
  );
}

export default CenterOptionRegis;
