import LoadPanel from 'app/components/LoadPanel';
import { TYPE_TOKEN } from 'constant';
import { WEB_SOCKET_URL } from 'constant/dataConstant';
import useProfileInfo from 'hooks/useProfileInfo';
import React, { useEffect } from 'react';
import tokenService from 'services/auth.service';
import * as SockJS from 'sockjs-client';
import * as Stomp from 'stompjs';

let stompClient: any = null;
export default function AlarmTest1() {
  const { userId }: any = useProfileInfo();
  useEffect(() => {
    if (!userId) return;
    connect();
    return () => {
      disconnect();
    };
  }, [userId]);

  const connect = () => {
    const token = `${TYPE_TOKEN} ${tokenService.getLocalAccessToken()}`;
    const socket = new SockJS(WEB_SOCKET_URL);
    // const socket = new SockJS('http://localhost:8085/ws');
    stompClient = Stomp.over(socket);
    stompClient.connect(
      {
        Authorization: token,
      },
      function (frame) {
        console.log('Connected: ' + frame);
        stompClient.subscribe(`/alarm/messages/${userId}`, function (message) {
          console.log(JSON.parse(message.body));
          showMessage(JSON.parse(message.body));
        });
      },
      function (error) {
        disconnect();
        console.error('Error connecting: ' + error);
      },
    );
  };

  function disconnect() {
    if (stompClient !== null) {
      stompClient.disconnect(function () {
        console.log('Disconnected');
      });
      stompClient = null; // Đặt về null để tạo mới khi cần
    }
  }

  function sendMessage() {
    if (stompClient) {
      const chatMessage = {
        sender: 'test',
        content: '1111111111111',
        type: 'CHAT',
      };
      stompClient.send('/alarm/sendMessage', {}, JSON.stringify(chatMessage));
    }
  }

  const showMessage = message => {
    var messageElement = window.document?.createElement('li');
    messageElement.appendChild(
      window.document?.createTextNode?.(
        message.sender + ': ' + message.content,
      ),
    );
    document.getElementById('messages')?.appendChild?.(messageElement);
  };

  return (
    <React.Suspense fallback={<LoadPanel visible={true} />}>
      <button onClick={sendMessage}>Send</button>
      <ul id="messages"></ul>
    </React.Suspense>
  );
}
