/**
 * Update date: 31-05-2023
 * Screen 2046.9
 */
import React, { useRef, useState } from 'react';
import { useInventoryReceiving } from '..';
import { DataGridFull } from 'app/components/DataGrid';
import LoadPanel from 'app/components/LoadPanel';
import useDictionary from 'hooks/useDictionary';
import moment from 'moment';
import useFormatTime from 'hooks/useFormatTime';
import useFormat from 'hooks/useFormat';
import PopupOrder from 'app/components/PopupCommon/PopupOrder';
const exportFileName =
  'SMDC_20417_2046.9 Center Forwarding Return Inquiry_' +
  moment().format('YYYYMMDD');

function ListInventory(props) {
  const { t }: any = useDictionary({ programId: '2046.9' });
  const context: any = useInventoryReceiving();
  const { dataInventory, loadingInventory } = context;
  const dataRef: any = useRef(null);
  const { DateFormat, DateTimeFormat } = useFormatTime();
  const { AmountFormat, QtyFormat, IntegerFormat } = useFormat();
  const [showPopupOrder, setShowPopupOrder] = useState<any>({
    visible: false,
    orderId: null,
  });

  /**
   * render status
   *
   * @param {*} e
   * @return {*}
   */
  const renderStatus = e => {
    return (
      <div>
        {e?.value === '0' ? t('Pending') : e?.value === '1' ? t('Fixed') : ''}
      </div>
    );
  };
  const columns: any = [
    {
      caption: t('Forward/Return'),
      alignment: 'center',
      items: [
        {
          dataField: 'taskTypeName',
          caption: t('Task Type'),
          showWhenGrouped: true,
        },
        {
          dataField: 'billId',
          caption: t('Bill ID'),
          showWhenGrouped: true,
        },
        {
          dataField: 'date',
          caption: t('Date'),
          dataType: 'date',
          format: DateFormat,
          alignment: 'left',
          showWhenGrouped: true,
        },
        {
          dataField: 'storeName',
          caption: t('Store'),
          showWhenGrouped: true,
        },
        {
          dataField: 'zoneName',
          caption: t('Zone'),
          showWhenGrouped: true,
        },
        {
          dataField: 'stateName',
          caption: t('State'),
          showWhenGrouped: true,
        },
        {
          dataField: 'status',
          caption: t('Status'),
          cellRender: renderStatus,
          showWhenGrouped: true,
        },
      ],
    },
    {
      caption: t('Product'),
      alignment: 'center',
      columns: [
        {
          dataField: 'productCode',
          caption: t('Product code'),
          showWhenGrouped: true,
        },
        {
          dataField: 'productName',
          caption: t('Product Name'),
          showWhenGrouped: true,
        },
        {
          dataField: 'marketableSize',
          caption: t('Marketable Size'),
          showWhenGrouped: true,
        },
        {
          dataField: 'optionCode',
          caption: t('Option Code'),
          showWhenGrouped: true,
        },
        {
          dataField: 'optionName',
          caption: t('Option'),
          showWhenGrouped: true,
        },
        {
          dataField: 'receivableCdName',
          caption: t('Receivable Group'),
          dataType: 'string',
          showWhenGrouped: true,
        },
        {
          dataField: 'depositProcessingTf',
          caption: t('Deposit Processing'),
          editorType: 'dxSwitch',
          dataType: 'boolean',
          showWhenGrouped: true,
        },
        {
          dataField: 'quantityPerPack',
          caption: t('Quantity Per Pack'),
          showWhenGrouped: true,
        },
      ],
    },
    {
      caption: t('Content'),
      alignment: 'center',
      columns: [
        {
          dataField: 'supplyUnitPrice',
          caption: t('Supply Unit Price'),
          format: AmountFormat,
          dataType: 'number',
          showWhenGrouped: true,
        },
        {
          dataField: 'quantity',
          caption: t('Qty'),
          format: QtyFormat,
          dataType: 'number',
          showWhenGrouped: true,
        },
        {
          dataField: 'ea',
          caption: t('EA'),
          format: QtyFormat,
          dataType: 'number',
          showWhenGrouped: true,
        },
        {
          dataField: 'supplyStandardUnitPrice',
          caption: t('Supply Standard Unit Price'),
          format: IntegerFormat,
          dataType: 'number',
          showWhenGrouped: true,
        },
        {
          dataField: 'marginRate',
          caption: t('Margin Rate'),
          format: IntegerFormat,
          dataType: 'number',
          showWhenGrouped: true,
        },
        {
          dataField: 'dcUnitPrice',
          caption: t('Discount Unit Price'),
          format: AmountFormat,
          dataType: 'number',
          showWhenGrouped: true,
        },
        {
          dataField: 'discountAmount',
          caption: t('Discount Amount'),
          format: AmountFormat,
          dataType: 'number',
          showWhenGrouped: true,
        },
        {
          dataField: 'amount',
          caption: t('Amount'),
          format: AmountFormat,
          dataType: 'number',
          showWhenGrouped: true,
        },
        {
          dataField: 'supplyAmount',
          caption: t('Supply Amount'),
          format: AmountFormat,
          dataType: 'number',
          showWhenGrouped: true,
        },
        {
          dataField: 'vat',
          caption: t('VAT'),
          format: AmountFormat,
          dataType: 'number',
          showWhenGrouped: true,
        },
        {
          dataField: 'bottle',
          caption: t('Bottle'),
          format: AmountFormat,
          dataType: 'number',
          showWhenGrouped: true,
        },
        {
          dataField: 'container',
          caption: t('Container'),
          format: AmountFormat,
          dataType: 'number',
          showWhenGrouped: true,
        },
        {
          dataField: 'shippingFee',
          caption: t('Shipping Fee'),
          alignment: 'right',
          format: AmountFormat,
          dataType: 'number',
          showWhenGrouped: true,
        },
        {
          dataField: 'addShippingAmount',
          caption: t('Add Shipping Amount'),
          alignment: 'right',
          format: AmountFormat,
          dataType: 'number',
          showWhenGrouped: true,
        },
        {
          dataField: 'gtotal',
          caption: t('G.Total'),
          alignment: 'right',
          format: AmountFormat,
          dataType: 'number',
          showWhenGrouped: true,
        },
      ],
    },
    {
      caption: t('Product Info'),
      alignment: 'center',
      columns: [
        {
          dataField: 'taxNm',
          caption: t('Tax Name'),
          showWhenGrouped: true,
        },
        {
          dataField: 'paymentMethod',
          caption: t('Payment Method'),
          showWhenGrouped: true,
        },
        {
          dataField: 'shippingRate',
          caption: t('Shipping Rate'),
          alignment: 'right',
          format: AmountFormat,
          dataType: 'number',
          showWhenGrouped: true,
        },
        {
          dataField: 'orderId',
          caption: t('Order ID'),
          showWhenGrouped: true,
        },
        {
          dataField: 'orderNumber',
          caption: t('Order Number'),
          alignment: 'left',
          showWhenGrouped: true,
          cellRender: e => {
            return (
              <div
                style={{ color: 'brown', cursor: 'pointer' }}
                onClick={() => {
                  setShowPopupOrder({
                    visible: true,
                    orderId: e?.data?.orderId,
                  });
                }}
              >
                {e?.value}
              </div>
            );
          },
        },
        {
          dataField: 'orderName',
          caption: t('Orderer Name'),
          showWhenGrouped: true,
        },
        {
          dataField: 'receiver',
          caption: t('Receiver'),
          showWhenGrouped: true,
        },
        {
          dataField: 'wayBill',
          caption: t('Way Bill'),
          showWhenGrouped: true,
        },
        {
          dataField: 'invoice',
          caption: t('Invoice'),
          showWhenGrouped: true,
        },
        {
          dataField: 'deliverDriveId',
          caption: t('Delivery Driver Id'),
          showWhenGrouped: true,
        },
        {
          dataField: 'deliveryDriverName',
          caption: t('Delivery Driver Name'),
          showWhenGrouped: true,
        },
      ],
    },
    {
      caption: t('Other'),
      alignment: 'center',
      visible: false,
      columns: [
        {
          dataField: 'supp_commision',
          caption: t('Vendor commission'),
          dataType: 'number',
          format: AmountFormat,
          showWhenGrouped: true,
        },
        {
          dataField: 'delivery_comm_rt',
          caption: t('Delivery Commission'),
          dataType: 'number',
          format: AmountFormat,
          showWhenGrouped: true,
        },
        {
          dataField: 'cash_amount',
          caption: t('Cash Amount'),
          dataType: 'number',
          format: AmountFormat,
          showWhenGrouped: true,
        },
        {
          dataField: 'coupon_center_amount',
          caption: t('Coupon Center Amount'),
          dataType: 'number',
          format: AmountFormat,
          showWhenGrouped: true,
        },
        {
          dataField: 'coupon_vendor_amount',
          caption: t('Coupon Vendor Amount'),
          dataType: 'number',
          format: AmountFormat,
          showWhenGrouped: true,
        },
        {
          dataField: 'correction_amount',
          caption: t('Correction Amount'),
          dataType: 'number',
          format: AmountFormat,
          showWhenGrouped: true,
        },
      ],
    },
    {
      dataField: 'billNote',
      caption: t('Bill Note'),
      visible: false,
    },
    {
      dataField: 'lineNote',
      caption: t('Line Note'),
      visible: false,
    },
    {
      dataField: 'bottleId',
      caption: t('Bottle ID'),
      visible: false,
    },
    {
      dataField: 'bottleUnitPrice',
      caption: t('Bottle Unit Price'),
      visible: false,
    },
    {
      dataField: 'containerId',
      caption: t('Container ID'),
      visible: false,
    },
    {
      dataField: 'containerUnitPrice',
      caption: t('Container Unit Price'),
      visible: false,
    },
    {
      dataField: 'makerName',
      caption: t('Maker'),
      visible: false,
    },
    {
      dataField: 'originName',
      caption: t('Origin'),
      visible: false,
    },
    {
      dataField: 'defaultVendorName',
      caption: t('Default Vendor'),
      visible: false,
    },
    {
      dataField: 'largeCategoryName',
      caption: t('Large Category'),
      visible: false,
    },
    {
      dataField: 'mediumCategoryName',
      caption: t('Medium Category'),
      visible: false,
    },
    {
      dataField: 'smallCategoryName',
      caption: t('Small Category'),
      visible: false,
    },
    {
      dataField: 'subCategoryName',
      caption: t('Sub Category'),
      visible: false,
    },
    {
      dataField: 'registerDateTime',
      caption: t('Register Datetime'),
      dataType: 'date',
      format: DateTimeFormat,
      alignment: 'left',
      visible: false,
    },
    {
      dataField: 'registerUserName',
      caption: t('Register User'),
      visible: false,
    },
    {
      dataField: 'registerProgramId',
      caption: t('Register ProgramID'),
      visible: false,
    },
    {
      dataField: 'modifyDateTime',
      caption: t('Modify Datetime'),
      dataType: 'date',
      format: DateTimeFormat,
      alignment: 'left',
      visible: false,
    },
    {
      dataField: 'modifyUserName',
      caption: t('Modify User'),
      visible: false,
    },
    {
      dataField: 'modifyProgramId',
      caption: t('Modify ProgramID'),
      visible: false,
    },
    {
      dataField: 'barcode',
      caption: t('Barcode'),
      visible: false,
    },
  ];
  /**
   * on export excel
   *
   */
  const exportExcel = () => {
    dataRef?.current?.instance?.exportToExcel();
  };

  return (
    <>
      <LoadPanel visible={loadingInventory} />
      <DataGridFull
        storageKeyInquiry="v1"
        columns={columns}
        ref={dataRef}
        dataSource={dataInventory}
        storageKey="2046.9"
        options={{
          groupPanel: {
            visible: true,
          },
          columnAutoWidth: true,
          onToolbarPreparing: (e: any) => {
            e.toolbarOptions.items.unshift(
              {
                location: 'before',
                template: 'totalCount',
              },
              {
                location: 'before',
                widget: 'dxButton',
                options: {
                  icon: 'xlsxfile',
                  text: t('Export Excel'),
                  disabled: !dataInventory.length,
                  onClick: () => exportExcel(),
                },
              },
            );
          },
          export: {
            fileName: exportFileName,
          },
          summary: {
            totalItems: [
              {
                column: 'ea',
                summaryType: 'sum',
                valueFormat: QtyFormat,
                dataType: 'number',
                displayFormat: '{0}',
              },
              {
                column: 'discountAmount',
                summaryType: 'sum',
                valueFormat: AmountFormat,
                dataType: 'number',
                displayFormat: '{0}',
              },
              {
                column: 'amount',
                summaryType: 'sum',
                valueFormat: AmountFormat,
                dataType: 'number',
                displayFormat: '{0}',
              },
              {
                column: 'supplyAmount',
                summaryType: 'sum',
                valueFormat: AmountFormat,
                dataType: 'number',
                displayFormat: '{0}',
              },
              {
                column: 'vat',
                summaryType: 'sum',
                valueFormat: AmountFormat,
                dataType: 'number',
                displayFormat: '{0}',
              },
              {
                column: 'bottle',
                summaryType: 'sum',
                valueFormat: AmountFormat,
                dataType: 'number',
                displayFormat: '{0}',
              },
              {
                column: 'container',
                summaryType: 'sum',
                valueFormat: AmountFormat,
                dataType: 'number',
                displayFormat: '{0}',
              },
              {
                column: 'shippingFee',
                summaryType: 'sum',
                valueFormat: AmountFormat,
                dataType: 'number',
                displayFormat: '{0}',
              },
              {
                column: 'addShippingAmount',
                summaryType: 'sum',
                valueFormat: AmountFormat,
                dataType: 'number',
                displayFormat: '{0}',
              },
              {
                column: 'gtotal',
                summaryType: 'sum',
                valueFormat: AmountFormat,
                dataType: 'number',
                displayFormat: '{0}',
              },
              {
                column: 'supp_commision',
                summaryType: 'sum',
                valueFormat: AmountFormat,
                dataType: 'number',
                displayFormat: '{0}',
              }, 
              {
                column: 'delivery_comm_rt',
                summaryType: 'sum',
                valueFormat: AmountFormat,
                dataType: 'number',
                displayFormat: '{0}',
              },
              {
                column: 'cash_amount',
                summaryType: 'sum',
                valueFormat: AmountFormat,
                dataType: 'number',
                displayFormat: '{0}',
              },
              {
                column: 'coupon_center_amount',
                summaryType: 'sum',
                valueFormat: AmountFormat,
                dataType: 'number',
                displayFormat: '{0}',
              },
              {
                column: 'coupon_vendor_amount',
                summaryType: 'sum',
                valueFormat: AmountFormat,
                dataType: 'number',
                displayFormat: '{0}',
              },
              {
                column: 'correction_amount',
                summaryType: 'sum',
                valueFormat: AmountFormat,
                dataType: 'number',
                displayFormat: '{0}',
              },
            ],
            groupItems: [
              {
                summaryType: 'count',
                displayFormat: '{0}',
              },
              {
                column: 'ea',
                summaryType: 'sum',
                alignByColumn: true,
                showInGroupFooter: false,
                valueFormat: AmountFormat,
                displayFormat: '{0}',
                dataType: 'number',
              },
              {
                column: 'discountAmount',
                summaryType: 'sum',
                alignByColumn: true,
                showInGroupFooter: false,
                valueFormat: AmountFormat,
                displayFormat: '{0}',
                dataType: 'number',
              },
              {
                column: 'amount',
                summaryType: 'sum',
                alignByColumn: true,
                showInGroupFooter: false,
                valueFormat: AmountFormat,
                displayFormat: '{0}',
                dataType: 'number',
              },
              {
                column: 'supplyAmount',
                summaryType: 'sum',
                alignByColumn: true,
                showInGroupFooter: false,
                valueFormat: AmountFormat,
                displayFormat: '{0}',
                dataType: 'number',
              },
              {
                column: 'vat',
                summaryType: 'sum',
                alignByColumn: true,
                showInGroupFooter: false,
                valueFormat: AmountFormat,
                displayFormat: '{0}',
                dataType: 'number',
              },
              {
                column: 'bottle',
                summaryType: 'sum',
                alignByColumn: true,
                showInGroupFooter: false,
                valueFormat: AmountFormat,
                displayFormat: '{0}',
                dataType: 'number',
              },
              {
                column: 'container',
                summaryType: 'sum',
                alignByColumn: true,
                showInGroupFooter: false,
                valueFormat: AmountFormat,
                displayFormat: '{0}',
                dataType: 'number',
              },
              {
                column: 'shippingFee',
                summaryType: 'sum',
                alignByColumn: true,
                showInGroupFooter: false,
                valueFormat: AmountFormat,
                displayFormat: '{0}',
                dataType: 'number',
              },
              {
                column: 'addShippingAmount',
                summaryType: 'sum',
                alignByColumn: true,
                showInGroupFooter: false,
                valueFormat: AmountFormat,
                displayFormat: '{0}',
                dataType: 'number',
              },
              {
                column: 'gtotal',
                summaryType: 'sum',
                alignByColumn: true,
                showInGroupFooter: false,
                valueFormat: AmountFormat,
                displayFormat: '{0}',
                dataType: 'number',
              },{
                column: 'supp_commision',
                summaryType: 'sum',
                alignByColumn: true,
                showInGroupFooter: false,
                valueFormat: AmountFormat,
                displayFormat: '{0}',
                dataType: 'number',
              },{
                column: 'delivery_comm_rt',
                summaryType: 'sum',
                alignByColumn: true,
                showInGroupFooter: false,
                valueFormat: AmountFormat,
                displayFormat: '{0}',
                dataType: 'number',
              },{
                column: 'cash_amount',
                summaryType: 'sum',
                alignByColumn: true,
                showInGroupFooter: false,
                valueFormat: AmountFormat,
                displayFormat: '{0}',
                dataType: 'number',
              },{
                column: 'coupon_center_amount',
                summaryType: 'sum',
                alignByColumn: true,
                showInGroupFooter: false,
                valueFormat: AmountFormat,
                displayFormat: '{0}',
                dataType: 'number',
              },{
                column: 'coupon_vendor_amount',
                summaryType: 'sum',
                alignByColumn: true,
                showInGroupFooter: false,
                valueFormat: AmountFormat,
                displayFormat: '{0}',
                dataType: 'number',
              },{
                column: 'correction_amount',
                summaryType: 'sum',
                alignByColumn: true,
                showInGroupFooter: false,
                valueFormat: AmountFormat,
                displayFormat: '{0}',
                dataType: 'number',
              },
            ],
          },
        }}
      />
      {showPopupOrder?.visible && showPopupOrder?.orderId && (
        <PopupOrder
          orderId={showPopupOrder?.orderId}
          onClose={() => {
            setShowPopupOrder({ visible: false, orderId: null });
          }}
        />
      )}
    </>
  );
}

export default ListInventory;
