import React, { memo } from 'react';
import { useLocation } from 'react-router-dom';
import { WrapContent } from 'app/components/WrapContent';
import CenterZone from 'app/pages/CenterZone';
import RegisterProgram from 'app/pages/RegisterProgramPage';
import RegisteringStore from 'app/pages/RegisteringStore';
import UserRegistration from 'app/pages/UserRegistration';
import CommonCodeRegistration from 'app/pages/CommonCode';
import CenterRegistration from 'app/pages/SystemManagementPage/CenterRegisterPage/CenterRegistration';
import ProductRegistration from 'app/pages/ProductRegistration';
import ContainerRegistration from 'app/pages/ContainerRegistration';
import ModificationReceiving from 'app/pages/ModificationReceiving';
import ReceivingInquiry from 'app/pages/ReceivingInquiry';
import LostAndOtherAccountsForwarding from 'app/pages/LostAndOtherAccountsForwarding';
import ProductGroupRegistration from 'app/pages/ProductGroupRegistration';
import InventoryReceivingForwardingHistoryByProduct from 'app/pages/InventoryReceivingForwardingHistoryByProduct';
import Order from 'app/pages/Order';
import Forward from 'app/pages/Forward';
import ForcedCenterReturn from 'app/pages/ForcedCenterReturn';
import InventoryReceivingAndForwarding from 'app/pages/InventoryReceivingAndForwarding';
import InventoryCount from 'app/pages/InventoryCount';
import InquiresAboutTheReturnSchedule from 'app/pages/InquiresAboutTheReturnSchedule';
import RegistrationSchedule from 'app/pages/RegistrationSchedule';
import CarryoutInquiry from 'app/pages/CarryoutInquiry';
import ReturnableAmount from 'app/pages/ReturnableAmount';
import CenterReturnControlRegis from 'app/pages/CenterReturnControlRegis';
import Deposit from 'app/pages/Deposit';
import ProductCost from 'app/pages/ProductCost';
import OrderRegistration from 'app/pages/OrderRegistration';
import InventoryCostStatusByProduct from 'app/pages/InventoryCostStatusByProduct';
import RegistrationMember from 'app/pages/RegistrationMember';
import MemberInquiry from 'app/pages/MemberInquiry';
import CenterForwardingReturnInquiry from 'app/pages/CenterForwardingReturnInquiry';
import OrderInquiry from 'app/pages/OrderInquiry';
import RegularOrder from 'app/pages/RegularOrder';
import RegularOrderQuantityComfirm from 'app/pages/RegularOrderQuantityComfirm';
import BundlePackageRegistration from 'app/pages/BundlePackageRegistration';
import MallProductMaster from 'app/pages/MallProductMaster';
import SupplyUnitPriceGroupMarginRateManagement from 'app/pages/SupplyUnitPriceGroupMarginRateManagement';
import ProcessingTakenOut from 'app/pages/ProcessingTakenOut';
import RegistrationDirectDelivery from 'app/pages/RegistrationDirectDelivery';
import RegisterDirectDeliveryEmptyBottles from 'app/pages/RegisterDirectDeliveryEmptyBottles';
import ProductMaster from 'app/pages/ProductMaster';
import B2BSendHistory from 'app/pages/B2BSendHistory';
import HUBSendHistory from 'app/pages/HUBSendHistory';
import ReceivingAggregationComparedToOrders from 'app/pages/ReceivingAggregationComparedToOrders';
import SpecialDealInquiry from 'app/pages/ProductSpecialDealInquiry';
import RegistrationRecomentdedProducts from 'app/pages/RegistrationRecomentdedProducts';
import RegistrationSpecialDeal from 'app/pages/RegistrationSpecialDeal';
import StatusCarryIntoOfDirectDeliveryEmptyBottles from 'app/pages/StatusCarryIntoOfDirectDeliveryEmptyBottles';
import EmptyBottleCarryOutRegistration from 'app/pages/EmptyBottleCarryOutRegistration';
import RegisterMultilingualDictionary from 'app/pages/RegisterMultilingualDictionary';
import MemberBondBalanceInquiry from 'app/pages/MemberBondBalanceInquiry';
import MallLinkedInformationManagement from 'app/pages/MallLinkedInformationManagement';
import RegistrationStoreDeposit from 'app/pages/RegistrationStoreDeposit';
import RegistrationStoreDeduction from 'app/pages/RegistrationStoreDeduction';
import InquiryStoreDepositDeduction from 'app/pages/InquiryStoreDepositDeduction';
import RegistrationZoneMove from 'app/pages/RegistrationZoneMove';
import InquiriesZoneMove from 'app/pages/InquiriesZoneMove';
import InquiriesOrderHistory from 'app/pages/InquiriesOrderHistory';
import RegistrationOrderUploadForm from 'app/pages/RegistrationOrderUploadForm';
import StoreGroupRegistration from 'app/pages/StoreGroupRegistration';
import SupplyUnitPriceInquiryByMarket from 'app/pages/SupplyUnitPriceInquiryByMarket';
import LostAndOtherAccountsForwardingInquiry from 'app/pages/LostAndOtherAccountsForwardingInquiry';
import EmptyBottleInquiry from 'app/pages/EmptyBottleCarryOutInquiry';
import OutputOfPickList from 'app/pages/OutputOfPickList';
import ForwardingInspectionProcessing from 'app/pages/ForwardingInspectionProcessing';
import CommonCodeInquiry from 'app/pages/CommonCodeInquiry';
import ProductMasterInquiry from 'app/pages/ProductMasterInquiry';
import InventoryInquiry from 'app/pages/InventoryInquiryByProductLocation';
import OutputOfPickListCenterReceived from 'app/pages/OutputOfPicklistCenterReceived';
import InventoryInquiryLocation from 'app/pages/InventoryInquiryLocation';
import RegistrationInventoryDueDiligence from 'app/pages/RegistrationInventoryDueDiligence';
import OrderPickRequestManual from 'app/pages/OrderPickRequestManual';
import RegisteringVendor from 'app/pages/RegisteringVendor';
import ReportVendor from 'app/pages/ReportVendor';
import RegisterReverseShipping from 'app/pages/RegisterReverseShipping';
import ReportStore from 'app/pages/ReportStore';
import ConsignedDeliveryDetailInquiry from 'app/pages/ConsignedDeliveryDetailsInquiry';
import OrderDetailCheck from 'app/pages/OrderDetailCheck';
import OrderInquiryReceipt from 'app/pages/OrderInquiryReceipt';
import RegistrationOfRegularDeliveryProducts from 'app/pages/RegistrationOfRegularDeliveryProducts';
import ConsignmentShippingRequest from 'app/pages/ConsignmentShippingRequest';
import InquiriesSameDayShipmentSchedule from 'app/pages/InquiriesSameDayShipmentSchedule';
import DirectDistributionVehicleStandingInspection from 'app/pages/DirectDistributionVehicleStandingInspection';
import RegistrationForRegularDeliverySchedule from 'app/pages/RegistrationForRegularDeliverySchedule';
import CenterReceivingInspection from 'app/pages/CenterReceivingInspection';
import RegisterDelivery from 'app/pages/RegisterDelivery';
import EmptyBottleCarryDepositAPISend from 'app/pages/EmptyBottleCarryDepositAPISend';
import PrintDeliveryStatement from 'app/pages/PrintDeliveryStatement';
import DirectDeliveryConsignment from 'app/pages/DirectDeliveryConsignment';
import ProcessOrderUpload from 'app/pages/ProcessOrderUpload';
import ReceivingScheduledOrderDetailsInquiry from 'app/pages/ReceivingScheduledOrderDetailsInquiry';
import CreateRegularShippingOrders from 'app/pages/CreateRegularShippingOrders';
import RegisterDirectShippingPriority from 'app/pages/RegisterDirectShippingPriority';
import DirectShippingPriorityPC from 'app/pages/DirectShippingPriorityPC';
import ManualRegistrationPDA from 'app/pages/ManualRegistrationPDA';
import ManualRegistrationPC from 'app/pages/ManualRegistrationPC';
import RecommendedProductsByStoreSize from 'app/pages/RecommendedProductsByStoreSize';
import RegistrationOfScheduledDeliveryThroughBarcodeScan from 'app/pages/RegistrationOfScheduledDeliveryThroughBarcodeScan';
import InventoryAgeAnalysis from 'app/pages/InventoryAgeAnalysis';
import TrackingInquiry from 'app/pages/TrackingInquiry';
import InquiresOfScheduled from 'app/pages/InquiresOfScheduled';
import DueDiligenceOnInventoryInLocationPDA from 'app/pages/DueDiligenceOnInventoryInLocationPDA';
import ManageProductInterest from 'app/pages/ManageProductInterest';
import InquiryInverntoryDetailWarehouse from 'app/pages/InquirInventoryDetailWarehouse';
import InventoryComparison from 'app/pages/InventoryComparison';
import InquiryHUBStandardBarcode from 'app/pages/InquiryHUBStandardBarcode';
import InventoryComparisonTakeStock from 'app/pages/InventoryComparisonTakeStock';
import ReceiveLoadInquiry from '../ReceivingLoadInquiry';
import Statistic from 'app/pages/Statistic';
import InventoryStockTakeAdjustmentBeforeApproval from 'app/pages/InventoryStockTakeAdjustmentBeforeApproval';
import InquiresOfDefective from '../InquiresOfDefective';
import RegistrationDefectiveDisposal from 'app/pages/RegistrationDefectiveDisposal';
import InventoryDueDateRegistration from '../InventoryDueDateRegistration';
import ProductRotationRateInquiry from '../ProductRotationRateTopInquiry';
import ProductReceiveForwardInquiry from '../ProductReceiveForwardInquiry';
import CenterOptionRegis from '../CenterOptionRegis';
import HUBStandardBarcodeRegistration from 'app/pages/HUBStandardBarcodeRegistration';
import ReceivingRegistration from 'app/pages/ReceivingRegistration';
import ReceivingConfirmedPC from '../ReceivingConfirmedPC';
import IncomingLoadReg from '../IncomingLoadReg';
import ReceivingScheduledProductStackingLocationInquiry from 'app/pages/ReceivingScheduledProductStackingLocationInquiry';
import ProductForwardPriceInquiry from '../ProductForwardPriceInquiry';
import ProductWeekIndexInquiry from '../ProductWeekIndexInquiry';
import ProductBarcodePrint from '../ProductBarcodePrint';
import ProductForwardingStandardPriceRegistration from 'app/pages/ProductForwardingStandardPriceRegistration';
import CategoryRegistration from 'app/pages/CategoryRegistration';
import ConsignmentDeliveryInquiry from '../OrderConsignmentDeliveryInquiry';
import WorkflowChartERP from '../WorkflowChartERP';
import BundlePackageInquiry from '../BundlePackageInquiry';
import RegisterShippingStaff from '../RegisterShippingStaff';
import M2081_E from '../M2081_E';
import ModifyingForwarding from '../ModifyingForwarding';
import M2011_7 from '../M2011_7';
import M1043_7 from '../M1043_7';
import M1043_8 from '../M1043_8';
import SMSDelivery from '../SMSDelivery';
import M2052_A from '../M2052_A';
import PrintPicklistStatement from '../PrintPicklistStatement';
import M2043_4 from '../M2043_4';
import M2011_8 from '../M2011_8';
import ProductExpirationDate from '../ProductExpirationDate';
import { Notification } from 'app/components/Notification';
import ExpirationDateRegistration from '../ExpirationDateRegistration';
import SalesStatisticsByMember from '../SalesStatisticsByMemeber';
import PurchaseSettlementRegistration from '../PurchaseSettlementRegistration';
import ECSDataSend from '../ECSDataSend';
import DepositCheckByOrder from '../DepositCheckByOrder';
import PurchaseSettleAndWithdrawalRegis from '../PurchaseSettleAndWithdrawalRegis';
import VendorWithDrawalRegis from '../VendorWithDrawalRegis';
import AccountPayableAdjustmentReg from '../AccountPayableAdjustmentReg';
import TradePayableBalanceInquiry from '../TradePayableBalanceInquiry';
import AccountPayableInquiryByVendor from '../AccountPayableInquiryByVendor';
import M2082_6 from '../M2082_6_Purchase_Summary';
import SaleSummaryByStore from '../SaleSummaryByStore';
import AccountReceivableInquiryByStore from '../AccountReceivableInquiryByStore';
import { NotFoundPage } from 'app/components/NotFoundPage';
import M2081_H from '../M2081_H';
import M2086_1 from '../M2086_1';
import MemberMonthlyDuesBillingInquiry from '../MemberMonthlyDuesBillingInquiry';
// ================= not use pohang ============
import M2016_7 from '../M2016_7';
import M2016_8 from '../M2016_8';
import M2016_9 from '../M2016_9';
import M1047_1 from '../M1047_1';
import M2011_B from '../M2011_B';
// ============================================
import M2013_2 from '../M2013_2';
import M2082_8 from '../M2082_8';
import M2051_A from '../M2051_A';
import M2051_9 from '../M2051_9';
import M2051_B from '../M2051_B';
import M2013_6 from '../M2013_6';
import M2013_7 from '../M2013_7';
import M2087_4 from '../M2087_4';
import M1044_6 from '../M1044_6';
import M2082_9 from '../M2082_9';
import M2016_5 from '../M2016_5';
import M2016_6 from '../M2016_6';
import M2087_3 from '../M2087.3';
import M2087_2 from '../M2087_2';
import M2086_2 from '../M2086_2';
import M2051_C from '../M2051_C';
import M2044_8 from '../M2044_8';
import M2086_3 from '../M2086_3';
import M2046_C from '../M2046_C';
import M2082_A from '../M2082_A';
import M2087_9 from '../M2087_9';
import M2087_A from '../M2087_A';
import M2044_9 from '../M2044_9';
import M2046_D from '../M2046_D';
import M2082_B from '../M2082_B';
import M2087_B from '../M2087_B';
import M2081_G from '../M2081_G';
import M2081_I from '../M2081.I';
import M2044_2T from '../M2044_2t';
import M2054_B from '../M2054_B';
import M2054_C from '../M2054_C';
import M2042_6 from '../M2042_6';
import M2054_E from '../M2054_E';
import M1044_7 from '../M1044_7';
import M2013_8 from '../M2013_8';
import M2011_9 from '../M2011_9';
import M1044_8 from '../M1044_8';
import RegisterMileageSettingsByProduct from '../RegisterMileageSettingsByProduct';
import CancelFixByOperation from '../CancelFixByOperation';
import StoreProductListInquiry from '../StoreProductListInquiry';
import M2044_A from '../M2044_A';
import ESLDesignSetting from '../ESLDesignSetting';
import M2086_4 from '../M2086_4';
import M2017_5 from '../M2017_5';
import M2044_B from '../M2044_B';
import M2043_8 from '../M2043_8';
import M2044_C from '../M2044_C';
import M2011_C from '../M2011_C';
import M2052_B from '../M2052_B';
import M2023_4 from '../M2023_4';
import M2041_A from '../M2041_A';
import M2041_B from '../M2041_B';
import M2052_C from '../M2052_C';
import M2082_C from '../M2082_C';
import M1044_9 from '../M1044_9';
import M2087_C from '../M2087_C';
import M2082_E from '../M2082_E';
import M2044_5 from '../M2024_5';
import M2051_D from '../M2051_D';
import M2082_G from '../M2082_G';
import M2088_1 from '../M2088_1';
import M2023_5 from '../M2023_5';
import M1041_2 from '../M1041_2';
import M2082_D from '../M2082_D';
import AlarmTest from '../AlarmTest';

function DynamicPage() {
  const location: any = useLocation();
  const str = location?.pathname?.split('/')?.[3];
  const programId = location?.state?.programId3 || str;
  let renderComp;

  if (location?.pathname === '/notification') {
    return (
      <WrapContent>
        <Notification />
      </WrapContent>
    );
  }

  switch (programId) {
    // ================= not use pohang ============
    case '2016.7':
      renderComp = <M2016_7 />;
      break;
    case '2016.8':
      renderComp = <M2016_8 />;
      break;
    case '2016.9':
      renderComp = <M2016_9 />;
      break;
    case '1047.1':
      renderComp = <M1047_1 />;
      break;
    case '2011.B':
      renderComp = <M2011_B />;
      break;
    // =============================================
    case '1001.1':
      renderComp = <WorkflowChartERP />;
      break;
    case '1032.1':
      renderComp = <Statistic />;
      break;
    case '1041.1':
      renderComp = <RegisterProgram />;
      break;
    case '1041.2':
      renderComp = <M1041_2 />;
      break;
    case '1042.1':
      renderComp = <CenterRegistration />;
      break;
    case '1042.2':
      renderComp = <RegisterMultilingualDictionary />;
      break;
    case '1042.3':
      renderComp = <CategoryRegistration />;
      break;
    case '1042.4':
      renderComp = <CommonCodeRegistration />;
      break;
    case '1042.5':
      renderComp = <CenterOptionRegis />;
      break;
    case '1042.6':
      renderComp = <CommonCodeInquiry />;
      break;
    case '1042.7':
      renderComp = <ESLDesignSetting />;
      break;
    case '1043.1':
      renderComp = <UserRegistration />;
      break;
    case '1043.3':
      renderComp = <CenterZone />;
      break;
    case '1043.7':
      renderComp = <M1043_7 />;
      break;
    case '1043.8':
      renderComp = <M1043_8 />;
      break;
    case '1044.1':
      renderComp = <RegisteringVendor />;
      break;
    case '1044.2':
      renderComp = <RegisteringStore />;
      break;
    case '1044.3':
      renderComp = <ReportVendor />;
      break;
    case '1044.4':
      renderComp = <ReportStore />;
      break;
    case '1046.A':
      renderComp = <MallLinkedInformationManagement />;
      break;
    case '1044.5':
      renderComp = <StoreGroupRegistration />;
      break;
    case '1044.6':
      renderComp = <M1044_6 />;
      break;
    case '1044.7':
      renderComp = <M1044_7 />;
      break;
    case '1044.8':
      renderComp = <M1044_8 />;
      break;
    case '1044.9':
      renderComp = <M1044_9 />;
      break;
    case '2011.A':
      renderComp = <RegisterMileageSettingsByProduct />;
      break;
    case '2011.1':
      renderComp = <ProductRegistration />;
      break;
    case '2011.2':
      renderComp = <ProductBarcodePrint />;
      break;
    case '2011.3':
      renderComp = <ProductGroupRegistration />;
      break;
    case '2011.4':
      renderComp = <ContainerRegistration />;
      break;
    case '2011.5':
      renderComp = <ProductMasterInquiry />;
      break;
    case '2011.6':
      renderComp = <ProductExpirationDate />;
      break;
    case '2011.7':
      renderComp = <M2011_7 />;
      break;
    case '2011.8':
      renderComp = <M2011_8 />;
      break;
    case '2011.9':
      renderComp = <M2011_9 />;
      break;
    case '2011.C':
      renderComp = <M2011_C />;
      break;
    case '2012.3':
      renderComp = <RecommendedProductsByStoreSize />;
      break;
    case '2012.4':
      renderComp = <RegistrationRecomentdedProducts />;
      break;
    case '2012.5':
      renderComp = <ManageProductInterest />;
      break;
    case '2013.1':
      renderComp = <ProductForwardingStandardPriceRegistration />;
      break;
    case '2013.2':
      renderComp = <M2013_2 />;
      break;
    case '2013.3':
      renderComp = <ProductForwardPriceInquiry />;
      break;
    case '2013.4':
      renderComp = <RegistrationSpecialDeal />;
      break;
    case '2013.5':
      renderComp = <SpecialDealInquiry />;
      break;
    case '2013.6':
      renderComp = <M2013_6 />;
      break;
    case '2013.7':
      renderComp = <M2013_7 />;
      break;
    case '2013.8':
      renderComp = <M2013_8 />;
      break;
    case '2013.9':
      renderComp = <StoreProductListInquiry />;
      break;
    case '2014.1':
      renderComp = <InquiryHUBStandardBarcode />;
      break;
    case '2014.2':
      renderComp = <HUBStandardBarcodeRegistration />;
      break;
    case '2016.1':
      renderComp = <BundlePackageRegistration />;
      break;
    case '2016.4':
      renderComp = <BundlePackageInquiry />;
      break;
    case '2016.5':
      renderComp = <M2016_5 />;
      break;
    case '2016.6':
      renderComp = <M2016_6 />;
      break;
    case '2017.1':
      renderComp = <MallProductMaster />;
      break;
    case '2017.2':
      renderComp = <B2BSendHistory />;
      break;
    case '2017.3':
      renderComp = <ProductMaster />;
      break;
    case '2017.4':
      renderComp = <HUBSendHistory />;
      break;
    case '2017.5':
      renderComp = <M2017_5 />;
      break;
    case '2021.1':
      renderComp = <RegularOrder />;
      break;
    case '2021.3':
      renderComp = <RegularOrderQuantityComfirm />;
      break;
    case '2023.1':
      renderComp = <OrderRegistration />;
      break;
    case '2023.2':
      renderComp = <OrderInquiry />;
      break;
    case '2023.3':
      renderComp = <ReceivingAggregationComparedToOrders />;
      break;
    case '2023.4':
      renderComp = <M2023_4 />;
      break;
    case '2023.5':
      renderComp = <M2023_5 />;
      break;
    case '2024.1':
      renderComp = <ConsignmentShippingRequest />;
      break;
    case '2024.3':
      renderComp = <ConsignedDeliveryDetailInquiry />;
      break;
    case '2024.5':
      renderComp = <M2044_5 />;
      break;
    case '2031.1':
      renderComp = <OrderInquiryReceipt />;
      break;
    case '2031.2':
      renderComp = <OrderDetailCheck />;
      break;
    case '2032.1':
      renderComp = <RegistrationOfScheduledDeliveryThroughBarcodeScan />;
      break;
    case '2032.2':
      renderComp = <InquiresOfScheduled />;
      break;
    case '2032.3':
      renderComp = <RegisterDelivery />;
      break;
    case '2032.5':
      renderComp = <PrintDeliveryStatement />;
      break;
    case '2033.1':
      renderComp = <DirectDeliveryConsignment />;
      break;
    case '2033.3':
      renderComp = <ConsignmentDeliveryInquiry />;
      break;
    case '2041.1':
      renderComp = <ReceivingScheduledOrderDetailsInquiry />;
      break;
    case '2041.2':
      renderComp = <ReceivingScheduledProductStackingLocationInquiry />;
      break;
    case '2041.3':
      renderComp = <ReceivingRegistration />;
      break;
    case '2041.4':
      renderComp = <IncomingLoadReg />;
      break;
    case '2041.C':
      renderComp = <Order />;
      break;
    case '2041.5':
      renderComp = <ReceivingConfirmedPC />;
      break;
    case '2041.6':
      renderComp = <ModificationReceiving />;
      break;
    case '2041.7':
      renderComp = <ReceivingInquiry />;
      break;
    case '2041.8':
      renderComp = <ReceiveLoadInquiry />;
      break;
    case '2041.9':
      renderComp = <ExpirationDateRegistration />;
      break;
    case '2041.A':
      renderComp = <M2041_A />;
      break;
    case '2041.B':
      renderComp = <M2041_B />;
      break;
    case '2042.1':
      renderComp = <RegistrationSchedule />;
      break;
    case '2042.2':
      renderComp = <InquiresAboutTheReturnSchedule />;
      break;
    case '2042.4':
      renderComp = <ProcessingTakenOut />;
      break;
    case '2042.5':
      renderComp = <CarryoutInquiry />;
      break;
    case '2042.6':
      renderComp = <M2042_6 />;
      break;
    case '2043.1':
      renderComp = <RegistrationOrderUploadForm />;
      break;
    case '2043.2':
      renderComp = <ProcessOrderUpload />;
      break;
    case '2043.3':
      renderComp = <CreateRegularShippingOrders />;
      break;
    case '2043.4':
      renderComp = <M2043_4 />;
      break;
    case '2043.6':
      renderComp = <InquiriesOrderHistory />;
      break;
    case '2043.7':
      renderComp = <InquiriesSameDayShipmentSchedule />;
      break;
    case '2043.8':
      renderComp = <M2043_8 />;
      break;
    case '2044.2':
      renderComp = <OrderPickRequestManual />;
      break;
    case '2044.2t':
      renderComp = <M2044_2T />;
      break;
    case '2044.3':
      renderComp = <OutputOfPickList />;
      break;
    case '2044.4':
      renderComp = <OutputOfPickListCenterReceived />;
      break;
    case '2044.5':
      renderComp = <RegisterReverseShipping />;
      break;
    case '2044.7':
      renderComp = <PrintPicklistStatement />;
      break;
    case '2044.8':
      renderComp = <M2044_8 />;
      break;
    case '2044.9':
      renderComp = <M2044_9 />;
      break;
    case '2044.A':
      renderComp = <M2044_A />;
      break;
    case '2044.B':
      renderComp = <M2044_B />;
      break;
    case '2044.C':
      renderComp = <M2044_C />;
      break;
    case '2045.1':
      renderComp = <ECSDataSend />;
      break;
    case '2046.1':
      renderComp = <ForwardingInspectionProcessing />;
      break;
    case '2046.2':
      renderComp = <DirectDistributionVehicleStandingInspection />;
      break;
    case '2046.3':
      renderComp = <CenterReceivingInspection />;
      break;
    case '2046.4':
      renderComp = <Forward />;
      break;
    case '2046.5':
      renderComp = <LostAndOtherAccountsForwarding />;
      break;
    case '2046.6':
      renderComp = <ForcedCenterReturn />;
      break;
    case '2046.7':
      renderComp = <CenterReturnControlRegis />;
      break;
    case '2046.8':
      renderComp = <ReturnableAmount />;
      break;
    case '2046.9':
      renderComp = <CenterForwardingReturnInquiry />;
      break;
    case '2046.A':
      renderComp = <LostAndOtherAccountsForwardingInquiry />;
      break;
    case '2046.B':
      renderComp = <ModifyingForwarding />;
      break;
    case '2046.C':
      renderComp = <M2046_C />;
      break;
    case '2046.D':
      renderComp = <M2046_D />;
      break;
    case '2047.1':
      renderComp = <RegistrationOfRegularDeliveryProducts />;
      break;
    case '2047.2':
      renderComp = <RegistrationForRegularDeliverySchedule />;
      break;
    case '2048.1':
      renderComp = <RegistrationZoneMove />;
      break;
    case '2048.2':
      renderComp = <InquiriesZoneMove />;
      break;
    case '2051.1':
      renderComp = <InventoryCount />;
      break;
    case '2051.2':
      renderComp = <InventoryReceivingAndForwarding />;
      break;
    case '2051.3':
      renderComp = <InventoryReceivingForwardingHistoryByProduct />;
      break;
    case '2051.4':
      renderComp = <ProductCost />;
      break;
    case '2051.6':
      renderComp = <InventoryCostStatusByProduct />;
      break;
    case '2051.8':
      renderComp = <InventoryAgeAnalysis />;
      break;
    case '2051.9':
      renderComp = <M2051_9 />;
      break;
    case '2051.A':
      renderComp = <M2051_A />;
      break;
    case '2051.B':
      renderComp = <M2051_B />;
      break;
    case '2051.C':
      renderComp = <M2051_C />;
      break;
    case '2051.D':
      renderComp = <M2051_D />;
      break;
    case '2052.A':
      renderComp = <M2052_A />;
      break;
    case '2052.B':
      renderComp = <M2052_B />;
      break;
    case '2052.C':
      renderComp = <M2052_C />;
      break;
    case '2052.1':
      renderComp = <ManualRegistrationPDA />;
      break;
    case '2052.2':
      renderComp = <ManualRegistrationPC />;
      break;
    case '2052.3':
      renderComp = <InventoryInquiry />;
      break;
    case '2052.5':
      renderComp = <InventoryInquiryLocation />;
      break;
    case '2053.1':
      renderComp = <ProductRotationRateInquiry />;
      break;
    case '2053.2':
      renderComp = <ProductReceiveForwardInquiry />;
      break;
    case '2053.3':
      renderComp = <ProductWeekIndexInquiry />;
      break;
    case '2054.1':
      renderComp = <RegistrationInventoryDueDiligence />;
      break;
    case '2054.3':
      renderComp = <DueDiligenceOnInventoryInLocationPDA />;
      break;
    case '2054.6':
      renderComp = <InquiryInverntoryDetailWarehouse />;
      break;
    case '2054.8':
      renderComp = <InventoryComparison />;
      break;
    case '2054.9':
      renderComp = <InventoryStockTakeAdjustmentBeforeApproval />;
      break;
    case '2054.A':
      renderComp = <InventoryDueDateRegistration />;
      break;
    case '2054.B':
      renderComp = <M2054_B />;
      break;
    case '2054.C':
      renderComp = <M2054_C />;
      break;
    case '2054.D':
      renderComp = <InventoryComparisonTakeStock />;
      break;
    case '2054.E':
      renderComp = <M2054_E />;
      break;
    case '2055.1':
      renderComp = <RegistrationDefectiveDisposal />;
      break;
    case '2055.2':
      renderComp = <InquiresOfDefective />;
      break;
    case '2061.1':
      renderComp = <RegisterDirectShippingPriority />;
      break;
    case '2061.4':
      renderComp = <RegistrationDirectDelivery />;
      break;
    case '2061.5':
      renderComp = <DirectShippingPriorityPC />;
      break;
    case '2064.5':
      renderComp = <RegisterDirectDeliveryEmptyBottles />;
      break;
    case '2064.6':
      renderComp = <StatusCarryIntoOfDirectDeliveryEmptyBottles />;
      break;
    case '2064.7':
      renderComp = <EmptyBottleCarryOutRegistration />;
      break;
    case '2064.8':
      renderComp = <EmptyBottleInquiry />;
      break;
    case '2064.9':
      renderComp = <EmptyBottleCarryDepositAPISend />;
      break;
    case '2081.1':
      renderComp = <RegistrationMember />;
      break;
    case '2081.2':
      renderComp = <MemberInquiry />;
      break;
    case '2081.3':
      renderComp = <Deposit />;
      break;
    case '2081.5':
      renderComp = <DepositCheckByOrder />;
      break;
    case '2081.7':
      renderComp = <SupplyUnitPriceGroupMarginRateManagement />;
      break;
    case '2081.8':
      renderComp = <SupplyUnitPriceInquiryByMarket />;
      break;
    case '2081.9':
      renderComp = <MemberBondBalanceInquiry />;
      break;
    case '2081.B':
      renderComp = <RegistrationStoreDeposit />;
      break;
    case '2081.C':
      renderComp = <RegistrationStoreDeduction />;
      break;
    case '2081.D':
      renderComp = <InquiryStoreDepositDeduction />;
      break;
    case '2081.E':
      renderComp = <M2081_E />;
      break;
    case '2081.F':
      renderComp = <SMSDelivery />;
      break;
    case '2081.G':
      renderComp = <M2081_G />;
      break;
    case '2081.H':
      renderComp = <M2081_H />;
      break;
    case '2081.I':
      renderComp = <M2081_I />;
      break;
    case '2081.J':
      renderComp = <MemberMonthlyDuesBillingInquiry />;
      break;
    case '2081.K':
      renderComp = <CancelFixByOperation />;
      break;
    case '2082.1':
      renderComp = <PurchaseSettlementRegistration />;
      break;
    case '2082.2':
      renderComp = <PurchaseSettleAndWithdrawalRegis />;
      break;
    case '2082.4':
      renderComp = <AccountPayableAdjustmentReg />;
      break;
    case '2082.5':
      renderComp = <TradePayableBalanceInquiry />;
      break;
    case '2082.6':
      renderComp = <M2082_6 />;
      break;
    case '2082.7':
      renderComp = <AccountPayableInquiryByVendor />;
      break;
    case '2082.8':
      renderComp = <M2082_8 />;
      break;
    case '2082.9':
      renderComp = <M2082_9 />;
      break;
    case '2082.3':
      renderComp = <VendorWithDrawalRegis />;
      break;
    case '2082.A':
      renderComp = <M2082_A />;
      break;
    case '2082.B':
      renderComp = <M2082_B />;
      break;
    case '2082.C':
      renderComp = <M2082_C />;
      break;
    case '2082.D':
      renderComp = <M2082_D />;
      break;
    case '2082.G':
      renderComp = <M2082_G />;
      break;
    case '2082.E':
      renderComp = <M2082_E />;
      break;
    case '2083.1':
      renderComp = <RegisterShippingStaff />;
      break;
    case '2083.3':
      renderComp = <TrackingInquiry />;
      break;
    case '2086.1':
      renderComp = <M2086_1 />;
      break;
    case '2086.2':
      renderComp = <M2086_2 />;
      break;
    case '2086.3':
      renderComp = <M2086_3 />;
      break;
    case '2086.4':
      renderComp = <M2086_4 />;
      break;
    case '2087.2':
      renderComp = <M2087_2 />;
      break;
    case '2087.3':
      renderComp = <M2087_3 />;
      break;
    case '2087.4':
      renderComp = <M2087_4 />;
      break;
    case '2087.C':
      renderComp = <M2087_C />;
      break;
    case '2087.5':
      renderComp = <SalesStatisticsByMember />;
      break;
    case '2087.6':
      renderComp = <SaleSummaryByStore />;
      break;
    case '2087.7':
      renderComp = <AccountReceivableInquiryByStore />;
      break;
    case '2087.9':
      renderComp = <M2087_9 />;
      break;
    case '2087.A':
      renderComp = <M2087_A />;
      break;
    case '2087.B':
      renderComp = <M2087_B />;
      break;
    case '2088.1':
      renderComp = <M2088_1 />;
      break;
    case 'alarm':
      renderComp = <AlarmTest />;
      break;
    default:
      renderComp = <NotFoundPage />;
      break;
  }
  return <WrapContent>{renderComp}</WrapContent>;
}

export default memo(DynamicPage);
